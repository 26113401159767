import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import clamp from 'lodash/clamp'
import analyticsUtils from '../../helpers/analytics-utils'
import { throttle } from '../../helpers'
import CosmeticScrollbar from '../cosmetic-scrollbar'
import style from './index.module.scss'

const THROTTLE_SCROLL = 250

function useMakeTrackScroll({
  eventScrollThreshold,
  scrollNode,
  eventCategory,
  eventAction,
}) {
  const [hasTrackedScroll, setHasTrackedScroll] = useState(false)
  return () => {
    if (
      scrollNode.current &&
      eventScrollThreshold !== undefined &&
      scrollNode.current.scrollLeft > eventScrollThreshold &&
      !hasTrackedScroll
    ) {
      const hasAnalyticsEvent = Boolean(eventCategory && eventAction)
      if (hasAnalyticsEvent) {
        setHasTrackedScroll(true)
        analyticsUtils.DEPRECATED_gaTagTracking({
          event: analyticsUtils.events.MODULE_IMPRESSION,
          eventCategory,
          eventAction,
        })
      }
    }
  }
}

function useManageScrolling({
  scrollNode,
  useCosmeticScrollbar,
  setCosmeticScrollPos,
}) {
  const [showLeftElem, setShowLeftElem] = useState(false)
  const [showRightElem, setShowRightElem] = useState(true)
  const lastScrollPosRef = useRef(0)
  return useMemo(
    () => ({
      showLeftElem,
      showRightElem,
      showCosmeticScrollbar:
        useCosmeticScrollbar && (showLeftElem || showRightElem),
      handleScrollNoTrack: () => {
        const elem = scrollNode.current
        if (!elem) {
          return
        }
        const containerWidth = elem.clientWidth
        const scrollLeft = elem.scrollLeft
        const contentWidth = elem.scrollWidth
        lastScrollPosRef.current = scrollLeft
        if ([containerWidth, scrollLeft, contentWidth].includes(undefined)) {
          return
        }
        const newShowLeftElem = scrollLeft > 0
        const newShowRightElem = scrollLeft + containerWidth < contentWidth
        if (
          newShowLeftElem !== showLeftElem ||
          newShowRightElem !== showRightElem
        ) {
          setShowLeftElem(newShowLeftElem)
          setShowRightElem(newShowRightElem)
        }
        if (useCosmeticScrollbar) {
          const maxPosition = contentWidth - containerWidth
          const positionAsPercentage = Math.round(
            (scrollLeft / maxPosition) * 100
          )
          /*
           * touch devices can make scroll requests beyond min/max
           * that 'snap' back when touch is released
           */
          const percentage = clamp(positionAsPercentage, 0, 100)
          setCosmeticScrollPos(percentage || 0)
        }
      },
    }),
    [
      scrollNode,
      showLeftElem,
      showRightElem,
      setCosmeticScrollPos,
      useCosmeticScrollbar,
    ]
  )
}

function trackScrollDirection({ direction, eventCategory, eventAction }) {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.MODULE_IMPRESSION,
    eventCategory,
    eventAction,
    eventLabel:
      direction === 'forward'
        ? analyticsUtils.labels.ARROW_RIGHT
        : analyticsUtils.labels.ARROW_LEFT,
  })
}

function scrollPaddingFromRef(scrollNode) {
  const elem = scrollNode.current
  if (!elem) {
    return 0
  }
  const elemStyle = window.getComputedStyle(elem)
  return (
    (parseFloat(elemStyle.paddingLeft) || 0) +
    (parseFloat(elemStyle.paddingRight) || 0)
  )
}

function scrollPageSizeFromRef({ scrollNode, extraScroll }) {
  const elem = scrollNode.current
  if (!elem) {
    return 0
  }
  const visibleWidth = elem.clientWidth - scrollPaddingFromRef(scrollNode)
  const contentWidth = elem.scrollWidth
  const pages = contentWidth / visibleWidth
  return contentWidth / pages + extraScroll
}

function moveScrollNoTrackingFromRef({ direction, scrollNode, extraScroll }) {
  const elem = scrollNode.current
  const sizePerPg = scrollPageSizeFromRef({ scrollNode, extraScroll })
  let scrollPos = elem.scrollLeft
  if (direction === 'forward') {
    scrollPos += sizePerPg
  } else {
    scrollPos -= sizePerPg
  }
  elem.scrollLeft = scrollPos
}

function HorizontalScrollWrapper({
  children,
  className,
  contentClassName,
  leftElem,
  rightElem,
  eventScrollThreshold,
  eventCategory,
  eventAction,
  extraScroll = 0,
  useCosmeticScrollbar = true,
}) {
  const scrollNode = useRef(null)
  const [cosmeticScrollPos, setCosmeticScrollPos] = useState(
    useCosmeticScrollbar ? 0 : null
  )
  const trackScroll = useMakeTrackScroll({
    eventScrollThreshold,
    scrollNode,
    eventAction,
    eventCategory,
  })
  const {
    showLeftElem,
    showRightElem,
    showCosmeticScrollbar,
    handleScrollNoTrack,
  } = useManageScrolling({
    scrollNode,
    useCosmeticScrollbar,
    setCosmeticScrollPos,
  })
  const handleScroll = useCallback(() => {
    trackScroll()
    handleScrollNoTrack()
  }, [trackScroll, handleScrollNoTrack])

  useEffect(handleScroll, [handleScroll])
  const moveScroll = useCallback(
    direction => {
      moveScrollNoTrackingFromRef({ direction, scrollNode, extraScroll })
      trackScrollDirection({ direction, eventAction, eventCategory })
    },
    [eventAction, eventCategory, extraScroll]
  )

  return (
    <Fragment>
      <div
        className={classNames(
          style.HorizontalScrollWrapper,
          style.evolved,
          className
        )}
      >
        {Boolean(leftElem) &&
          leftElem({
            isVisible: showLeftElem,
            onClick: () => moveScroll('back'),
          })}
        <div className={style.scrollContentWrapper}>
          <div
            ref={scrollNode}
            className={classNames(style.scrollContent, contentClassName)}
            onScroll={throttle(handleScroll, THROTTLE_SCROLL)}
          >
            {children}
          </div>
        </div>
        {Boolean(rightElem) &&
          rightElem({
            isVisible: showRightElem,
            onClick: () => moveScroll('forward'),
          })}
      </div>
      {showCosmeticScrollbar && (
        <div className={classNames(style.cosmeticWrapper, style.evolved)}>
          <div className={style.cosmeticInner}>
            <CosmeticScrollbar
              lightTheme={false}
              position={cosmeticScrollPos}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

HorizontalScrollWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  leftElem: PropTypes.func,
  rightElem: PropTypes.func,
  eventScrollThreshold: PropTypes.number,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
  useCosmeticScrollbar: PropTypes.bool,
}

export default HorizontalScrollWrapper
