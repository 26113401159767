import React from 'react'
import { UserDeviceContext } from '../../context'
import { getDisplayName } from '../get-display-name'
export { TouchEventDetection } from './touch-event-detection'

export const withTouchDetection = WrappedComponent => {
  const wrapper = props => (
    <UserDeviceContext.Consumer>
      {value => (
        <WrappedComponent isUsingTouch={value.isUsingTouch} {...props} />
      )}
    </UserDeviceContext.Consumer>
  )

  wrapper.displayName = `withTouchDetection(${getDisplayName(
    WrappedComponent
  )})`
  return wrapper
}
