import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ArrowLeftSmallIcon, ArrowRightSmallIcon } from '@hub/icon'
import HorizontalScrollWrapper from '../horizontal-scroll-wrapper'
import { TABINDEX_NOT_TABBABLE } from '../../helpers/a11y-helpers'
import analyticsUtils from '../../helpers/analytics-utils'
import { matchMedia, containerMinWidthQuery } from '../../helpers/match-media'
import { withTouchDetection } from '../../helpers/with-touch-detection'
import style from './index.module.scss'

class DeckScrollWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.containerMatchMedia = matchMedia(containerMinWidthQuery)
    this.state = {
      // Is the screen wider than the container's max-width?
      isContainerMaxWidth: this.containerMatchMedia.matches,
    }
  }

  componentDidMount() {
    this.containerMatchMedia.addListener(this.containerSizeChanged)
  }

  componentWillUnmount() {
    this.containerMatchMedia.removeListener(this.containerSizeChanged)
  }

  /**
   * Helper for derived state – should the left/right buttons be shown?
   * They are hidden when the both of these conditions apply:
   * - Device is using touch events (e.g. iOS, laptop with touch interactions).
   * - Screen width is small enough that the images flow to the screen edge.
   */
  shouldShowButtons = () =>
    this.state.isContainerMaxWidth || !this.props.isUsingTouch

  containerSizeChanged = event => {
    this.setState({ isContainerMaxWidth: event.matches })
  }

  render() {
    const {
      bgColor,
      children,
      className,
      contentClassName,
      eventCategory,
      eventAction,
    } = this.props
    const showButtons = this.shouldShowButtons()
    return (
      <HorizontalScrollWrapper
        bgColor={bgColor}
        className={classNames(className, style.evolved)}
        contentClassName={classNames(style.scrollContent, contentClassName)}
        extraScroll={8}
        eventScrollThreshold={
          analyticsUtils.settings.SCROLL_HORIZONTAL_THRESHOLD
        }
        eventCategory={eventCategory}
        eventAction={eventAction}
        useCosmeticScrollbar={false}
        leftElem={({ isVisible, onClick }) => (
          <div
            className={classNames(style.backButton, style.evolvedButton, {
              [style.enabled]: showButtons && isVisible,
            })}
            tabIndex={TABINDEX_NOT_TABBABLE}
            aria-hidden="true"
            onClick={onClick}
          >
            <ArrowLeftSmallIcon />
          </div>
        )}
        rightElem={({ isVisible, onClick }) => (
          <div
            className={classNames(style.forwardButton, style.evolvedButton, {
              [style.enabled]: showButtons && isVisible,
            })}
            tabIndex={TABINDEX_NOT_TABBABLE}
            aria-hidden="true"
            data-test-id="forward-button"
            onClick={onClick}
          >
            <ArrowRightSmallIcon />
          </div>
        )}
      >
        {children}
      </HorizontalScrollWrapper>
    )
  }
}

DeckScrollWrapper.propTypes = {
  isUsingTouch: PropTypes.bool,
  bgColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
}

export default withTouchDetection(DeckScrollWrapper)

DeckScrollWrapper.displayName = 'DeckScrollWrapper'
